import { decamelizeKeys } from "humps"
import { get, put, del } from "highline/api/v2_client"
import { toDecamelizedJSON } from "highline/utils/immutable_helper"
import { schema } from "highline/api/base"

const checkoutHeader = `application/json;v=2.0;schema=${schema}.checkout`

export const fetch = (number, token, skip_shipping_and_tax_calc) => {
  return get(`/carts/${number}`,
    {
      skip_shipping_and_tax_calc,
    },
    {
      "Accept": checkoutHeader,
      "Cache-Control": "no-cache, no-store",
      "X-Cart-Token": token,
    })
}

export const fetchByUser = (authToken) => {
  return get("/cart",
    {},
    {
      "Accept": checkoutHeader,
      "Cache-Control": "no-cache, no-store",
      "X-Authentication-Token": authToken,
    })
}

export const addAddress = (number, token, address, updateTaxes) => {
  const params = updateTaxes ? { update_taxes: "true" } : {}
  return put(`/carts/${number}/address`,
    params,
    { address: toDecamelizedJSON(address) },
    {
      "Accept": checkoutHeader,
      "X-Cart-Token": token,
    },
  )
}

export const updateShippingRate = (number, token, shippingRate, addressAttr = null) => {
  const params = addressAttr ? {
    address: toDecamelizedJSON(addressAttr),
    shipping_rate: toDecamelizedJSON(shippingRate),
  } : {
    shipping_rate: toDecamelizedJSON(shippingRate),
  }

  return put(`/carts/${number}/shipping_rate`,
    {},
    params,
    {
      "Accept": checkoutHeader,
      "X-Cart-Token": token,
    },
  )
}

export const addBillingInformation = (number, token, billingInformation) => {
  return put(`/carts/${number}/credit_card`,
    {},
    { credit_card: toDecamelizedJSON(billingInformation) },
    {
      "Accept": checkoutHeader,
      "X-Cart-Token": token,
    },
  )
}


export const addGiftCard = (number, token, nonce, securityCode) => {
  const giftCardInfo = decamelizeKeys( {
    cardDetails: {
      nonce,
      securityCode,
    },
    processor: "adyen",
  })

  return put(`/carts/${number}/gift_card`,
    {},
    { gift_card: giftCardInfo },
    {
      "Accept": checkoutHeader,
      "X-Cart-Token": token,
    },
  )
}

export const applyPromo = (number, token, promotion) => {
  return put(`/carts/${number}/promotion`,
    {},
    { promotion },
    {
      "Accept": checkoutHeader,
      "X-Cart-Token": token,
    },
  )
}

export const removePromo = (number, token) => {
  return del(`/carts/${number}/promotion`,
    {},
    {},
    {
      "Accept": checkoutHeader,
      "X-Cart-Token": token,
    },
  )
}

export const complete = (number, token, cartData) => {
  return put(`/carts/${number}/complete`,
    {},
    toDecamelizedJSON(cartData),
    {
      "Accept": checkoutHeader,
      "X-Cart-Token": token,
    },
  )
}

export const removeLineItems = (number, token, items) => {
  return del(`/carts/${number}/items`,
    {},
    { items: toDecamelizedJSON(items) },
    {
      "Accept": checkoutHeader,
      "X-Cart-Token": token,
    },
  )
}

export const associateCartWithUser = (number, cartToken, authToken) => {
  return put(`/carts/${number}/associate_user`,
    {},
    {},
    {
      "Accept": checkoutHeader,
      "X-Cart-Token": cartToken,
      "X-Authentication-Token": authToken,
    },
  )
}
