import ActionTypes from "highline/redux/action_types"
import * as LocationApi from "highline/api/location_api"

export const countriesRequestStarted = () => ({
  type: ActionTypes.COUNTRIES_REQUEST_STARTED,
})

export const countriesFetchSucceeded = (countries) => ({
  countries,
  type: ActionTypes.COUNTRIES_FETCH_SUCCEEDED,
})

export const countriesFetchFailed = () => ({
  type: ActionTypes.COUNTRIES_FETCH_FAILED,
})

export const countriesRequestCompleted = () => ({
  type: ActionTypes.COUNTRIES_REQUEST_COMPLETED,
})

export const regionsUpdated = (regions) => ({
  regions,
  type: ActionTypes.REGIONS_UPDATED,
})

export const fetchCountries = () => (
  async (dispatch, getState) => {
    const isLoaded = getState().getIn(["location", "isLoaded"])
    if (isLoaded) {
      return
    }

    dispatch(countriesRequestStarted())
    try {
      const response = await LocationApi.fetchCountries()
      dispatch(countriesFetchSucceeded(response.data.get("countries")))

    } catch (error) {
      if ([401, 404].includes(error.status)) {
        //logCartFetchFailed(error)
        return dispatch(countriesFetchFailed())

      } else {
        setTimeout(() => { throw error })
      }
    }

    return dispatch(countriesRequestCompleted())
  }
)
