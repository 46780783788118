import * as WalletApi from "highline/api/wallet_api"
import * as CheckoutApi from "highline/api/checkout_api"
import ActionTypes from "highline/redux/action_types"
import { signIn } from "highline/utils/navigate"
import * as UserAuthStorage from "highline/storage/user_auth_storage"
import {
  getAddressForRequest,
  getVCBillingInformationForRequest,
  getBillingInformationForRequest,
  getBillingInformationForUpdate,
  getNonceForRequest,
  isUnauthorized,
} from  "highline/redux/helpers/checkout_helper"
import {
  orderUpdateSucceeded,
  orderUpdateFailed,
  orderFatalErrorReceived,
} from "highline/redux/actions/order_actions"
import {
  billingInformationStateReset,
  billingInformationAddSucceeded,
  billingInformationAddFailed,
  billingInformationRecaptchaAsync, 
} from "highline/redux/actions/billing_information_actions"

export const walletAddNewPaymentClicked = () => ({
  paymentMethodType: "card",
  type: ActionTypes.WALLET_ADD_NEW_PAYMENT_CLICKED,
})

export const walletAddPaymentFailed = (error) => ({
  error,
  type: ActionTypes.WALLET_ADD_PAYMENT_FAILED,
})

export const walletAddPaymentSucceeded = (payments, newPayment) => ({
  newPayment,
  paymentMethodType: "card",
  payments,
  type: ActionTypes.WALLET_ADD_PAYMENT_SUCCEEDED,
})

export const walletBackClicked = () => ({
  type: ActionTypes.WALLET_BACK_CLICKED,
})

export const walletCloseClicked = () => ({
  type: ActionTypes.WALLET_CLOSE_CLICKED,
})

export const walletDeletePaymentSucceeded = (payments, paymentId) => ({
  paymentId,
  payments,
  type: ActionTypes.WALLET_DELETE_PAYMENT_SUCCEEDED,
})

export const walletDeletePaymentFailed = (error) => ({
  error,
  type: ActionTypes.WALLET_DELETE_PAYMENT_FAILED,
})

export const walletRequestStarted = () => ({
  type: ActionTypes.WALLET_REQUEST_STARTED,
})

export const walletFetchSucceeded = (payments) => ({
  payments,
  type: ActionTypes.WALLET_FETCH_SUCCEEDED,
})

export const walletFetchFailed = (error) => ({
  error,
  type: ActionTypes.WALLET_FETCH_FAILED,
})

export const walletRequestCompleted = () => ({
  type: ActionTypes.WALLET_REQUEST_COMPLETED,
})

export const walletOpenClicked = () => ({
  paymentMethodType: "card",
  type: ActionTypes.WALLET_OPEN_CLICKED,
})

export const walletAffirmCheckoutSucceeded = () => ({
  type: ActionTypes.WALLET_AFFIRM_CHECKOUT_SUCCEEDED,
})

export const fetchWalletAsync = (shouldOpenActionDialog = true) => (
  async (dispatch, getState) => {
    dispatch(walletRequestStarted())

    const token = getState().getIn(["auth", "authenticationToken"]) || UserAuthStorage.load().authenticationToken

    if (token) {
      try {
        const response = await WalletApi.show(token)
        dispatch(walletFetchSucceeded(response.data))

        if (shouldOpenActionDialog) {
          dispatch(walletOpenClicked())
        }
      } catch (errors) {
        dispatch(walletFetchFailed(errors.data))

        if (isUnauthorized(errors)) {
          UserAuthStorage.remove()
          signIn({ redirect_to: "checkout/address" })
        }
      }
    }

    return dispatch(walletRequestCompleted())
  }
)

export const walletAddPaymentAsync = (shouldCloseActionDialog = false) => (
  async (dispatch, getState) => {
    dispatch(walletRequestStarted())

    const billingInformation = getState().get("billingInformation")
    const order = getState().get("order")
    const isSameAsShippingAddress = billingInformation.get("isSameAsShippingAddress")
    const address = isSameAsShippingAddress ? order.get("address") : billingInformation.get("address")
    const addressForRequest = getAddressForRequest(address)
    const isDefault = billingInformation.get("isDefault")
    const isInWallet = billingInformation.get("isInWallet")
    const name = billingInformation.get("name")

    const nonce = getNonceForRequest(billingInformation)

    const walletInformationForRequest = getBillingInformationForRequest(
      "adyen",
      isInWallet,
      isDefault,
      nonce,
      name,
      addressForRequest,
    )

    const token = getState().getIn(["auth", "authenticationToken"]) || UserAuthStorage.load().authenticationToken

    if (token) {
      try {
        await dispatch(billingInformationRecaptchaAsync())
        const response = await WalletApi.create(token, walletInformationForRequest)
        dispatch(walletAddPaymentSucceeded(response.data, billingInformation))
        dispatch(billingInformationStateReset())

        if (shouldCloseActionDialog) {
          dispatch(walletCloseClicked())
        }
      } catch (errors) {
        dispatch(walletAddPaymentFailed(errors.data))
      }
    }

    return dispatch(walletRequestCompleted())
  }
)

export const walletBackAsync = () => (
  async (dispatch) => {
    dispatch(billingInformationStateReset())
    dispatch(walletBackClicked())
  }
)

export const walletDeletePaymentAsync = (paymentId) => (
  async (dispatch, getState) => {
    dispatch(walletRequestStarted())
    const token = getState().getIn(["auth", "authenticationToken"]) || UserAuthStorage.load().authenticationToken

    if (token) {
      try {
        const response = await WalletApi.destroy(token, paymentId)
        dispatch(walletDeletePaymentSucceeded(response.data, paymentId))
      } catch (errors) {
        dispatch(walletDeletePaymentFailed(errors.data))
      }
    }

    return dispatch(walletRequestCompleted())
  }
)

export const walletSelectPaymentForOrderAsync = (paymentId) => (
  async (dispatch, getState) => {
    dispatch(walletRequestStarted())
    const newPayment = getState().getIn(["wallet", "payments"])
      .filter((payment) => payment.get("id") == paymentId )
      .first()
    const order = getState().get("order")
    const number = order.get("number")
    const token = order.get("token")
    const id = newPayment.get("id")

    const billingInformationForRequest = getBillingInformationForUpdate(id)

    if (number && token) {
      try {
        const response = await CheckoutApi.addBillingInformation(number, token, billingInformationForRequest)
        dispatch(orderUpdateSucceeded(response.data.get("cart"), id))
        dispatch(walletCloseClicked())
      } catch (errors) {
        dispatch(orderUpdateFailed(errors.data))
      }
    }

    return dispatch(walletRequestCompleted())
  }
)

export const walletVisaCheckoutAsync = (visaCheckoutPayment) => (
  async (dispatch, getState) => {
    dispatch(walletRequestStarted())

    const order = getState().get("order")
    const number = order.get("number")
    const token = order.get("token")

    const billingInformationForRequest = getVCBillingInformationForRequest(visaCheckoutPayment)

    if (number && token) {
      try {
        const response = await CheckoutApi.addBillingInformation(number, token, billingInformationForRequest)
        const order = response.data.get("cart")
        dispatch(billingInformationAddSucceeded(order, "visa_checkout"))
        dispatch(walletCloseClicked())
      } catch (error) {
        if ([401, 404].includes(error.status)) {
          return dispatch(orderFatalErrorReceived(error.status, error.data))
        } else {
          dispatch(billingInformationAddFailed(error.data))
        }
      }
    }

    return dispatch(walletRequestCompleted())
  }
)

export const saveAndUseWalletInOrderAsync = () => (
  async (dispatch, getState) => {
    dispatch(walletRequestStarted())

    const billingInformation = getState().get("billingInformation")
    const order = getState().get("order")
    const isSameAsShippingAddress = billingInformation.get("isSameAsShippingAddress")
    const address = isSameAsShippingAddress ? order.get("address") : billingInformation.get("address")
    const addressForRequest = getAddressForRequest(address)
    const isDefault = billingInformation.get("isDefault")
    const isInWallet = billingInformation.get("isInWallet")
    const name = billingInformation.get("name")
    const number = order.get("number")
    const token = order.get("token")

    const nonce = getNonceForRequest(billingInformation)

    const billingInformationForRequest = getBillingInformationForRequest(
      "adyen",
      isInWallet,
      isDefault,
      nonce,
      name,
      addressForRequest,
    )
    if (number && token) {
      try {
        await dispatch(billingInformationRecaptchaAsync())
        const response = await CheckoutApi.addBillingInformation(number, token, billingInformationForRequest)
        dispatch(orderUpdateSucceeded(response.data.get("cart")))
        dispatch(walletCloseClicked())
      } catch (errors) {
        dispatch(walletAddPaymentFailed(errors.data))
      }
    }

    return dispatch(walletRequestCompleted())
  }
)
