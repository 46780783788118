import ActionTypes from "highline/redux/action_types"

export const bundleDiscountBundlePurchased = (order) => ({
  type: ActionTypes.BUNDLE_DISCOUNT_BUNDLE_PURCHASED,
  order,
})

export const bundleDiscountCrossCategoryBundlePurchased = (crossCategoryBundleProducts) => ({
  type: ActionTypes.BUNDLE_DISCOUNT_CROSS_CATEGORY_BUNDLE_PURCHASED,
  crossCategoryBundleProducts,
})
