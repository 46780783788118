import { List, fromJS } from "immutable"
import Rollbar, { formatHttpError } from "highline/utils/rollbar"
import * as CheckoutApi from "highline/api/checkout_api"
import * as CartApi from "highline/api/cart_api"
import * as OrderStorage from "highline/storage/order_storage"
import ActionTypes from "highline/redux/action_types"
import Router from "next/router"
import {
  getPromoCodeForRequest,
  getCompleteCheckoutDataForRequest,
  getCheckoutStep,
  isUserNotFound,
  sendABTastyOrderDetails,
  sendFriendBuyOrderDetail,
} from  "highline/redux/helpers/checkout_helper"
import {
  loadCartAsync,
} from "highline/redux/actions/cart_actions"
import {
  fetchWalletAsync,
} from "highline/redux/actions/wallet_actions"
import {
  bundleDiscountBundlePurchased,
  bundleDiscountCrossCategoryBundlePurchased,
} from "highline/redux/actions/bundle_discount_actions"
import { affirmCheckoutFailed, affirmCheckoutSucceeded, billingInformationAddFailed } from "highline/redux/actions/billing_information_actions"
import { getReferrerPathname } from "highline/utils/url"
import { hasAttachedCreditCardWithOnlyFailedPayments, createErrorForAttachedCreditCardWithOnlyFailedPayments } from "highline/utils/order_helper"
import * as UserAuthStorage from "highline/storage/user_auth_storage"
import { signIn } from "highline/utils/navigate"
import { authClearedAndRedirected } from "highline/redux/actions/auth_actions"
import getConfig from "highline/config/application"
import { trackCustomerPurchase } from "highline/api/constructor_api"

const { enablePaypalExpressCheckout } = getConfig()

export const orderEmailUpdateFailed = () => ({
  type: ActionTypes.ORDER_EMAIL_UPDATE_FAILED,
})

export const orderEmailUpdateStarted = () => ({
  type: ActionTypes.ORDER_EMAIL_UPDATE_STARTED,
})

export const orderEmailUpdateSucceeded = (email) => ({
  type: ActionTypes.ORDER_EMAIL_UPDATE_SUCCEEDED,
  email,
})

export const orderChangeEmailClicked = () => ({
  type: ActionTypes.ORDER_CHANGED_EMAIL_CLICKED,
})

export const orderChangeEmailCancelClicked = () => ({
  type: ActionTypes.ORDER_EMAIL_UPDATE_CANCELLED,
})

export const orderSummaryToggled = () => ({
  type: ActionTypes.ORDER_SUMMARY_TOGGLED,
})

export const orderRequestStarted = () => ({
  type: ActionTypes.ORDER_REQUEST_STARTED,
})

export const orderFetchSucceeded = (order) => ({
  order,
  type: ActionTypes.ORDER_FETCH_SUCCEEDED,
})

export const orderFetchFailed = () => ({
  type: ActionTypes.ORDER_FETCH_FAILED,
})

export const orderRequestCompleted = () => ({
  type: ActionTypes.ORDER_REQUEST_COMPLETED,
})

export const orderPromoCodeInputChanged = (name, value) => ({
  name,
  type: ActionTypes.ORDER_PROMO_CODE_INPUT_CHANGED,
  value,
})

export const orderSubmitPromoCodeSucceeded = (order) => ({
  order,
  type: ActionTypes.ORDER_SUBMIT_PROMO_CODE_SUCCEEDED,
})

export const orderSubmitPromoCodeFailed = (error) => ({
  error,
  type: ActionTypes.ORDER_SUBMIT_PROMO_CODE_FAILED,
})

export const orderSubmitCompleteSucceeded = (order) => ({
  order,
  type: ActionTypes.ORDER_SUBMIT_COMPLETE_SUCCEEDED,
})

export const orderSubmitCompleteFailed = (error) => ({
  error,
  type: ActionTypes.ORDER_SUBMIT_COMPLETE_FAILED,
})

export const orderStepLocationChanged = (redirectPath = "", path = "") => ({
  path,
  redirectPath,
  type: ActionTypes.ORDER_STEP_LOCATION_CHANGED,
})

export const orderUpdateSucceeded = (order, paymentId) => ({
  paymentId,
  order,
  type: ActionTypes.ORDER_UPDATE_SUCCEEDED,
})

export const orderUpdateFailed = (error) => ({
  error,
  type: ActionTypes.ORDER_UPDATE_FAILED,
})

export const orderConfirmationViewed = () => ({
  type: ActionTypes.ORDER_CONFIRMATION_VIEWED,
})

export const orderPageLocationExited = () => ({
  type: ActionTypes.ORDER_PAGE_LOCATION_EXITED,
})

export const orderNotFound = (error) => ({
  error,
  type: ActionTypes.ORDER_NOT_FOUND,
})

export const orderShippingRateChangedStarted = (shippingRate) => ({
  type: ActionTypes.ORDER_SHIPPING_RATE_CHANGED_STARTED,
  shippingRate,
})

export const orderShippingRateChangedSucceeded = (order) => ({
  order,
  type: ActionTypes.ORDER_SHIPPING_RATE_CHANGED_SUCCEEDED,
})

export const orderShippingRateChangedFailed = (error, oldShippingRate) => ({
  error,
  shippingRate: oldShippingRate,
  type: ActionTypes.ORDER_SHIPPING_RATE_CHANGED_FAILED,
})

export const orderDeleteLineItemSucceeded = (order, lineItem) => ({
  lineItem,
  order,
  type: ActionTypes.ORDER_DELETE_LINE_ITEM_SUCCEEDED,
})

export const orderDeleteLineItemFailed = (error) => ({
  error,
  type: ActionTypes.ORDER_DELETE_LINE_ITEM_FAILED,
})

export const orderFatalErrorReceived = (errorStatusCode, error) => ({
  error,
  errorStatusCode,
  type: ActionTypes.ORDER_FATAL_ERROR_RECEIVED,
})

export const checkoutStarted = () => ({
  type: ActionTypes.CHECKOUT_STARTED,
})

export const checkoutStepViewed = () => ({
  type: ActionTypes.CHECKOUT_STEP_VIEWED,
})

export const checkoutStepCompleted = () => ({
  type: ActionTypes.CHECKOUT_STEP_COMPLETED,
})

export const promoCodeEntered = () => ({
  type: ActionTypes.PROMO_CODE_ENTERED,
})

export const onToggleEditReviewShipping = () => ({
  type: ActionTypes.TOGGLE_REVIEW_SHIPPING_EDIT,
})

export const exitUserlessCheckoutStarted = () => ({
  type: ActionTypes.ORDER_EXIT_USERLESS_CHECKOUT_STARTED,
})

export const exitUserlessCheckoutSucceeded = () => ({
  type: ActionTypes.ORDER_EXIT_USERLESS_CHECKOUT_SUCCEEDED,
})

export const exitUserlessCheckoutFailed = () => ({
  type: ActionTypes.ORDER_EXIT_USERLESS_CHECKOUT_FAILED,
})

export const submitOrderRequestStarted = () => ({
  type: ActionTypes.SUBMIT_ORDER_REQUEST_STARTED,
})

export const editBillingCtaClicked = () => ({
  type: ActionTypes.ORDER_EDIT_BILLING_CTA_CLICKED,
})

export const exitCheckoutSucceeded = () => ({
  type: ActionTypes.EXIT_CHECKOUT_FLOW_SUCCEEDED,
})


export const exitCheckoutFlowAndOpenCartStarted = () => (
  async (dispatch) => {
    await Router.push("/")
    dispatch(loadCartAsync())
    dispatch(exitCheckoutSucceeded())
  }
)

export const exitUserlessCheckoutAsync = () => (
  async (dispatch, getState) => {
    dispatch(exitUserlessCheckoutStarted())
    const order = getState().get("order")
    const number = order.get("number")
    const token = order.get("token")
    const payments = order.get("payments") || []

    const paymentsAttributes = payments.map((x) => ({
      _destroy: true,
      id: x.get("id"),
    })).toJS()

    try {
      await CartApi.update(number, token, {
        payments_attributes: paymentsAttributes,
      })
      await Router.push("/")
      dispatch(loadCartAsync())
      dispatch(exitUserlessCheckoutSucceeded())
    } catch (error){
      dispatch(exitUserlessCheckoutFailed())
    }
  }
)

export const changeBillingCtaClickedAsync = () => (
  async (dispatch, getState) => {
    const isUserlessOrder = !getState().getIn(["auth", "isLoggedIn"])
    if (isUserlessOrder) {
      dispatch(editBillingCtaClicked())
      dispatch(exitUserlessCheckoutAsync())
    } else {
      dispatch(fetchWalletAsync())
    }
  }
)

export const fetchOrderAsync = (skipShippingAndTaxCalc = true) => (
  async (dispatch, getState) => {
    // This action is called in the layout and since the layout is mounted when
    // loading /checkout/confirmation page we need to avoid making a new requestCall
    // since it will return a 404 after order completion
    const isOrderCompleted = getState().getIn(["order", "isOrderCompleted"])
    if (isOrderCompleted) {
      return
    }

    const auth = getState().get("auth")
    const authenticationToken = auth.get("authenticationToken") || UserAuthStorage.load().authenticationToken

    if (!authenticationToken && !enablePaypalExpressCheckout) {
      signIn({ redirect_to: "checkout/address" })
      throw "You must be logged in"
    }

    dispatch(orderRequestStarted())

    const { number, token } = OrderStorage.load()
    try {
      let response
      if (number && token)
        response = await CheckoutApi.fetch(number, token, skipShippingAndTaxCalc)
      else {
        response = await CheckoutApi.fetchByUser(authenticationToken)
      }

      let order = response.data.get("cart")
      const isAnonymous = order.get("isAnonymous")

      if (isAnonymous && authenticationToken) {
        response = await CheckoutApi.associateCartWithUser(number, token, authenticationToken)
        order = response.data.get("cart")
      }

      const orderInState = getState().get("order")
      const isInitialLoad = orderInState.get("isInitialLoad")
      const step = getCheckoutStep(order)
      const currentPagePath = getState().getIn(["currentPage", "path"]) || window && window.location.pathname
      const redirectPath = step.get("redirectPath")
      const isRedirect = redirectPath && (`/checkout/${redirectPath}` !== currentPagePath)
      const payments = order.get("payments")
      const isUserlessOrder = !getState().getIn(["auth", "isLoggedIn"])
      const noPayments = !payments || (payments.size === 0)
      const onReviewPage = currentPagePath && currentPagePath.includes("checkout/review")

      if (noPayments && onReviewPage && isUserlessOrder){
        // Redirect if guest is on the review page with no payments
        dispatch(exitCheckoutFlowAndOpenCartStarted())
      } else if (isInitialLoad && isRedirect) {
        // Only peform redirect logic if we are landing on checkout for the first time
        const path = getReferrerPathname()
        dispatch(orderStepLocationChanged(redirectPath, path))
      } else if (onReviewPage && isRedirect && hasAttachedCreditCardWithOnlyFailedPayments(orderInState)){
        const errorObj = createErrorForAttachedCreditCardWithOnlyFailedPayments(orderInState)
        dispatch(billingInformationAddFailed(errorObj))
        dispatch(orderStepLocationChanged(redirectPath))
      }

      dispatch(orderFetchSucceeded(order))

      // Only when landing on checkout page
      if (isInitialLoad) {
        dispatch(checkoutStarted())
      }

      dispatch(checkoutStepViewed())
    } catch (error) {
      if (isUserNotFound(error.data)) {
        Rollbar.error("Checkout: associate_user api called failed", formatHttpError(error.data))
        dispatch(authClearedAndRedirected())
        signIn({ redirect_to: "checkout/address" })
        return
      }

      // Fire fetch failed action... keeping it to trigger segment event
      dispatch(orderFetchFailed())

      // If order not found, clear order from storage and redirect to homepage
      if (error.status === 404) {
        return dispatch(orderNotFound(error.data))
      }

      // If fatal then redirect to error page
      if ([401, 422].includes(error.status)) {
        return dispatch(orderFatalErrorReceived(error.status, error.data))
      }
    }

    return dispatch(orderRequestCompleted())
  }
)

export const submitPromoCodeAsync = () => (
  async (dispatch, getState) => {
    const order = getState().get("order")
    const number = order.get("number")
    const token = order.get("token")
    const promoCodeDetails = order.get("promoCodeDetails")
    const isPromoCodeApplied = promoCodeDetails.get("isPromoCodeApplied")
    const code = promoCodeDetails.get("code")

    if (!code) {
      return
    }

    dispatch(orderRequestStarted())
    dispatch(promoCodeEntered())

    if (number && token) {
      try {
        let requestCall = null

        if (isPromoCodeApplied) {
          requestCall = CheckoutApi.removePromo(number, token)
        } else {
          const promoCodeForRequest = getPromoCodeForRequest(promoCodeDetails)
          requestCall = CheckoutApi.applyPromo(number, token, promoCodeForRequest)
        }

        const response = await requestCall
        dispatch(orderSubmitPromoCodeSucceeded(response.data.get("cart")))
      } catch (error) {
        if ([401, 404].includes(error.status)) {
          return dispatch(orderFatalErrorReceived(error.status, error.data))
        } else {
          dispatch(orderSubmitPromoCodeFailed(error.data))
        }
      }
    }

    return dispatch(orderRequestCompleted())
  }
)

export const submitOrderCompleteAsync = (checkoutToken) => (
  async (dispatch, getState) => {
    const order = getState().get("order")
    const number = order.get("number")
    const signifydSessionId = order.get("signifydSessionId")
    const token = order.get("token")
    const isAffirmOrder = getState().getIn(["billingInformation", "paymentType"]) === "affirm"

    dispatch(submitOrderRequestStarted())

    if (number && token) {
      try {
        const address = order.get("address")
        const shippingRate = order.get("shippingRate")
        const cartData = getCompleteCheckoutDataForRequest(address, shippingRate, signifydSessionId, checkoutToken)
        const response = await CheckoutApi.complete(number, token, cartData)
        const orderDataFromResponse = response.data.get("cart")

        dispatch(orderSubmitCompleteSucceeded(orderDataFromResponse))

        if (orderDataFromResponse.get("bundleDiscountTotalNumeric")) {
          dispatch(bundleDiscountBundlePurchased(orderDataFromResponse))
        }
        const crossCategoryBundleProducts = orderDataFromResponse.get("items").filter((product) => (product.get("discountedBundles") &&product.get("discountedBundles").size > 0))
        if (crossCategoryBundleProducts && crossCategoryBundleProducts.size > 0) {
          dispatch(bundleDiscountCrossCategoryBundlePurchased(crossCategoryBundleProducts))
        }

        dispatch(orderStepLocationChanged("confirmation"))
        if (isAffirmOrder){
          dispatch(affirmCheckoutSucceeded())
        }
        trackCustomerPurchase(order)
      } catch (error) {
        if (isAffirmOrder) {
          dispatch(affirmCheckoutFailed("Something went wrong. Please try another payment method."))
        }
        if ([401, 404].includes(error.status)) {
          return dispatch(orderFatalErrorReceived(error.status, error.data))
        } else {
          dispatch(orderSubmitCompleteFailed(error.data))
          dispatch(fetchOrderAsync())
        }
      }
    }

    return dispatch(orderRequestCompleted())
  }
)

export const orderConfirmationViewedAsync = () => (
  async (dispatch, getState) => {
    const order = getState().get("order")
    const isOrderCompleted = getState().getIn(["order", "isOrderCompleted"])
    const email = getState().getIn(["auth", "email"])
    const { number, token } = OrderStorage.load()

    // fire action to clear number and token
    if (number && token && isOrderCompleted) {
      sendFriendBuyOrderDetail(order, email)
      sendABTastyOrderDetails(order)

      return dispatch(orderConfirmationViewed())
    }

    // When landing on checkout/confirmation and we have a cart then
    // redirect to checkout/address
    if (number && token && !isOrderCompleted) {
      return dispatch(orderStepLocationChanged("address"))
    }

    // redirect to homepage
    dispatch(orderPageLocationExited())
  }
)

export const orderShippingRateChangedAsync = (_name, value) => (
  async (dispatch, getState) => {
    const oldShippingRate = getState().getIn(["order", "shippingRate"])
    const newShippingRate = getState().getIn(["order", "availableShippingRates"]).find((rate) => rate.get("code") === value)
    const newShippingRateValue = fromJS({
      name: value,
    })

    const number = getState().getIn(["order", "number"])
    const token = getState().getIn(["order", "token"])

    dispatch(orderShippingRateChangedStarted(newShippingRate))

    if (number && token) {
      try {
        const response = await CheckoutApi.updateShippingRate(number, token, newShippingRateValue)
        dispatch(orderShippingRateChangedSucceeded(response.data.get("cart")))
      } catch (error) {
        if ([401, 404].includes(error.status)) {
          return dispatch(orderFatalErrorReceived(error.status, error.data))
        } else {
          dispatch(orderShippingRateChangedFailed(error.data, oldShippingRate))
        }
      }
    }

    return dispatch(orderRequestCompleted())
  }
)

export const deleteLineItemFromOrderAsync = (sku) => (
  async (dispatch, getState) => {
    const number = getState().getIn(["order", "number"])
    const token = getState().getIn(["order", "token"])
    const lineItem = getState().getIn(["order", "items"], List()).find(
      (lineItem) => lineItem.get("sku") === sku,
    )

    if (
      number &&
      token &&
      lineItem
    ) {
      try {
        const response = await CheckoutApi.removeLineItems(number, token, List([lineItem]))
        const order = response.data.get("cart")
        const items = order.get("items")

        // No more items then redirect user to homepage
        if (!items || items.isEmpty()) {
          return dispatch(orderPageLocationExited())
        }

        dispatch(orderDeleteLineItemSucceeded(order, lineItem))
      } catch (error) {
        if ([401, 404].includes(error.status)) {
          return dispatch(orderFatalErrorReceived(error.status, error.data))
        } else {
          dispatch(orderDeleteLineItemFailed(error.data))
        }
      }
    }
  }
)

export const submitEmailAsync = (email) => (

  async (dispatch, getState) => {
    dispatch(orderEmailUpdateStarted())

    const cart = getState().get("cart")
    const number = cart.get("number")
    const token = cart.get("token")
    if (number && token) {
      try {
        await CartApi.update(number, token, {
          email,
        })
        dispatch(orderEmailUpdateSucceeded(email))
      } catch (error) {
        dispatch(orderEmailUpdateFailed())
      }
    }
  }
)
