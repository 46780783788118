import getConfig from "highline/config/application"
import Rollbar from "highline/utils/rollbar"
import { post } from "highline/api/v2_client"

const { googleReCaptchaSiteKey, googleReCaptchaThreshold } = getConfig()

// Each reCAPTCHA user response token is valid for two minutes and can only be verified once to prevent replay attacks
// If you need a new token, you can re-run the reCAPTCHA verification.
// https://developers.google.com/recaptcha/docs/v3
export const recaptchaValidateAsync = async (authToken = "") => {
  const FALLBACK_RETURN_VALUE = true

  if (typeof window.grecaptcha === "undefined") {
    Rollbar.error("recaptchaValidate: window.grecaptcha undefined. Skipping validation check")
    return FALLBACK_RETURN_VALUE
  }

  try {
    const token = await window.grecaptcha.execute(googleReCaptchaSiteKey, { action: "submit" })
    const response = await post("/recaptcha/verify", { token }, { "X-Authentication-Token": authToken })

    if (!response.data.get("success")) {
      Rollbar.error(`Unsuccessful /recaptcha/verify call with errorCodes: ${ response.data.get("errorCodes") }`)
      return FALLBACK_RETURN_VALUE
    }

    const score = response.data.get("score")
    if (!score) return FALLBACK_RETURN_VALUE

    return score >= googleReCaptchaThreshold

  } catch (error) {
    Rollbar.error(`Error in recaptchaValidate: ${ error }`)
    return FALLBACK_RETURN_VALUE
  }
}